
let prepareStore = (res,app) => {
  let temp = res;
  temp.setup = true;
  temp.head = {};
  temp.address = `${res.address.address}, ${res.address.town || ""} ${
      res.address.postal_code || ""
    }`;
  if (res.eshop.logo) {
    temp.logo = res.eshop.logo;
    temp.negativeLogo = res.eshop.negativeLogo;
  }
  if (res.eshop.promoMessage) {
    temp.promoMessage = res.eshop.promoMessage;
  }
  if(!res.eshop.catalogRoute){
    res.eshop.catalogRoute = 'c';
  }
  if(res.eshop.cartRules){
    temp.cartRules = res.eshop.cartRules.filter(cr=>!cr.disabled);
  }
  if(res.eshop.var){
    res.eshop.var = (res.eshop.var||[]).reduce((acc,_)=>{
      acc[_.key] = _.value; 
      return acc;
    },{});
  }
  if(res.shipping){
    res.shipping = (res.shipping || []).map(s=>{
      s.options.forEach(so=>{
        s[`key_${so.key}`] = so.value;
      })
      return s;
    })
  }
  if(res.payment){
    res.payment = (res.payment || []).map(p=>{
      p.options.forEach(po=>{
        p[`key_${po.key}`] = po.value;
      })
      return p;
    })
  }
  temp.activePages = [];
  if(temp.eshop.elements){
    temp.eshop.elements = temp.eshop.elements.map(e=>{
      e.static = true;
      delete e.data;
      return e;
    })
  }
  let poolIcons = {
    home: '/template/default/light/Personal/home.svg',
    cart: '/template/default/light/Business/cart-alt.svg',
    search: '/template/default/light/WebandTechnology/search-alt.svg',
    inCart: '/template/default/light/eCommerce/shopping-cart-8.svg',
    toCart: '/template/default/light/eCommerce/shopping-cart-5.svg',
    close: '/template/default/light/InterfaceandSign/close.svg',
    user: '/template/default/light/WebandTechnology/user-alt.svg',
    phone: '/template/default/light/eCommerce/telephone-2.svg',
    email: '/template/default/light/Communication/envelope.svg',
    login: '/template/default/light/Direction/enter.svg',
    logout: '/template/default/light/Direction/exit.svg',
    save: '/template/default/light/WebandTechnology/save.svg',
    discount: '/template/default/light/eCommerce/discount-label.svg',
    menu: '/template/default/light/WebandTechnology/menu-alt.svg',
    grid: '/template/default/light/Design/grid-alt.svg',
    list: '/template/default/light/InterfaceandSign/list.svg',
    filter: '/template/default/light/InterfaceandSign/funnel-alt.svg',
    map: '/template/default/light/WebandTechnology/map.svg',
    plus: '/template/default/light/InterfaceandSign/plus.svg',
    minus: '/template/default/light/InterfaceandSign/minus.svg',
    gift: '/template/default/light/Personal/gift.svg',
    package: '/template/default/light/eCommerce/package-box.svg',
    delivery: '/template/default/light/eCommerce/delivery-truck-2.svg',
    wallet: '/template/default/light/Currency/wallet.svg',
    check: '/template/default/light/InterfaceandSign/checkmark.svg',
    uncheck: '/template/default/light/InterfaceandSign/circle.svg',
    delete: '/template/default/light/WebandTechnology/trash-can.svg',
    image: '/template/default/light/WebandTechnology/image.svg',
    pencil: '/template/default/light/Education/pencil.svg',
    arrow: '/template/default/light/Direction/chevron-right.svg',
    general: '/template/default/light/WebandTechnology/menu-alt-4.svg',
    warning: '/template/default/light/InterfaceandSign/warning.svg',
    box: '/template/default/light/eCommerce/box-4.svg',
    shipping: '/template/default/light/eCommerce/truck-box-1.svg',
    delivered: '/template/default/light/eCommerce/box-10.svg',
    processing:'/template/default/light/eCommerce/box-open.svg',
    mic:'/template/default/light/WebandTechnology/mic.svg'
  };
  Object.keys((res.eshop.icons || {})).forEach(k => {
    if (!res.eshop.icons[k]) {
      delete res.eshop.icons[k];
    }
  });
  temp.icons = {
    ...poolIcons,
    ...res.eshop.icons
  };
  temp.eshop.icons = temp.icons;
  temp.name = res.eshop.name ? res.eshop.name : "";
  temp.theme=res.eshop.theme?res.eshop.theme:'';
  temp.phone = res.eshop.phone ? res.eshop.phone : "";
  temp.email = res.eshop.email ? res.eshop.email : "";
  temp.logo = res.eshop.logo ? res.eshop.logo : "";
  temp.spinner = res.eshop.spinner ? res.eshop.spinner : 'ic-spinner-1';
  temp.negativeLogo = res.eshop.negativeLogo ?
    res.eshop.negativeLogo :
    "";
  temp.address = res.eshop.address ? res.eshop.address : "";
  temp.legalNameFooter = res.eshop.legalNameFooter ?
    res.eshop.legalNameFooter :
    "";
  temp.services = res.eshop.services;
  temp.faq = res.eshop.faq;
  temp.terms = res.eshop.terms;
  if (res.shippings) {
    temp.shippings = res.shippings.filter(s=>!s.disabled);
  }
  if (res.payments) {
    temp.payments = res.payments.filter(s=>!s.disabled);
  }
  if (res.plugins) {
    temp.plugins = res.plugins;
    temp.flat_plugins = {};
    res.plugins.forEach(p => {
      temp.flat_plugins[p.title] = {}
      p.options.forEach(po => {
        temp.flat_plugins[p.title][po.key] = po.value;
      })
    });
  }

  if(res.eshop.products){
    res.eshop.product = res.eshop.products.filter((p)=>!p.restrict_key)[0];
  }
  
  res.eshop.primary = res.eshop.primary || "#320a64";
  res.eshop.secondary = res.eshop.secondary || "#ff6914";
  res.eshop.alternative = res.eshop.alternative || "#234";
  res.eshop.subtle = res.eshop.subtle || "#fcfcfc";
  res.eshop.background = res.eshop.background || "#fff";
  res.eshop.shadow = res.eshop.shadow || "0px 4px 10px 1px #0004";
  res.eshop.fshadow = res.eshop.shadow.split(' ').filter((i, ii) => ii != 3).join(' ');

  if (res.eshop.fonts && res.eshop.fonts.regular && res.eshop.fonts.regular.name) {
    res.eshop.font_type = 'remote';
    res.eshop.font = res.eshop.fonts.regular.name;
    res.eshop.font_family = res.eshop.fonts.regular.name;
  } else {
    res.eshop.font = res.eshop.font || "Advent+Pro";
    if (res.eshop.font && res.eshop.font.includes("@")) {
      res.eshop.font_type = res.eshop.font.split("@")[0] || "google";
      res.eshop.font = res.eshop.font.split("@")[1];
    }
    res.eshop.font_family = res.eshop.font.replace(/\+/g, " ") || "Manrope";
  }

  (res.eshop.rounded = res.eshop.rounded || "0.8"),
  (res.eshop.textColor = res.eshop.textColor || "#111"),
  (res.eshop.textColorNegative = res.eshop.textColorNegative || "#fff"),
  (res.eshop.animation = res.eshop.animation || "300");

  temp.headScripts = res.eshop.headScripts;
  temp.bodyScripts = res.eshop.bodyScripts;
  

  temp.ready = true;
  if (
    temp._id &&
    temp.shippings &&
    temp.payments &&
    temp.eshop.logo &&
    temp.negativeLogo
  ) {
    temp.setup = false;
  }
  
  return temp;
};
export default prepareStore;
